import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const key = 'userInfo'
const isLogin = 'isLogin'

export default new Vuex.Store({
  state: {
		userInfo: null,
		isLogin: 0,
		token: '776e2cc64e7599f9af8fca3ec15a2837',
  },
  getters: {
		getStorage: function(state) {
			if(!state.userInfo) {
				state.userInfo = JSON.parse(localStorage.getItem(key))
				state.isLogin = localStorage.getItem(isLogin)
				state.access_token = localStorage.getItem('access_token')
			}
			return state.userInfo
		}
	},
  mutations: {
  	$_setLogin(state, value) {
			state.isLogin = value
			localStorage.setItem(isLogin, value)
		},
		$_setStorage(state, value) {
			state.userInfo = value
			localStorage.setItem(key, JSON.stringify(value))
		},
		$_removeStorage(state) {
			state.userInfo = null
			localStorage.removeItem(key)
		},
		$_setAccessToken(state, value) {
			state.access_token = value
			localStorage.setItem('access_token', value)
		},
  },
  actions: {
  	
  },
  modules: {
  	
  }
})
